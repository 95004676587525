define("discourse/plugins/df-core-private/initializers/df-core-private", ["exports", "discourse/controllers/discovery/categories", "discourse/routes/discovery-categories", "discourse/lib/plugin-api", "ember-addons/ember-computed-decorators"], function (_exports, _categories, _discoveryCategories, _pluginApi, _emberComputedDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'df-core-private',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.1', api => {
        api.decorateCooked(onDecorateCooked);
        // 2016-12-21 https://guides.emberjs.com/v2.4.0/object-model/reopening-classes-and-instances
        _categories.default.reopen(dt7948.p({
          categoryPageStyle(parentCategory) {
            this.siteSettings.desktop_category_page_style = parentCategory ? 'categories_with_featured_topics' : 'categories_and_latest_topics';
            return this._super(parentCategory);
          }
        }, [["method", "categoryPageStyle", [(0, _emberComputedDecorators.default)('model.parentCategory')]]]));
        _discoveryCategories.default.reopen({
          // 2024-05-31
          // «Cannot read properties of undefined (reading 'category_slug_path_with_id')»:
          // https://github.com/discourse-pro/df-core-private/issues/8
          model(params) {
            this.siteSettings.desktop_category_page_style = this.get('model.parentCategory') ? 'categories_with_featured_topics' : 'categories_and_latest_topics';
            return this._super(params);
          }
        });
      });
    }
  };
  /**
   * Удаляем ненужные параграфы, которые редактор вставляет
   * при наличии внутри элементов списка блоков кода.
   *
   * 2015-08-06
   * 1) decorateCooked вызывает своих подписчиков для каждого сообщения отдельно.
   * 2) $post содержит не только сообщение, но и элементы управления.
   * Чтобы применить свои селекторы только к сообщению,
   * используйте родительский селектор .cooked, например:
   * const $tables = $('.cooked > table', $post);
   * @used-by decorateCooked
   * @link https://github.com/discourse/discourse/blob/v1.4.0.beta7/app/assets/javascripts/discourse/lib/plugin-api.js.es6#L5
   * @param {jQuery} $post HTMLDivElement
   * @returns void
   */
  const onDecorateCooked = function ($post) {
    /**
     * @link http://api.jquery.com/prevall/
     * .prevAll() ввыбирает все предшествующие элементы-братья данного элемента
     * (опционально отфильтровывая их).
     */
    /** @type {jQuery} HTMLDivElement[] */
    $('.cooked li > pre', $post).prevAll('p').each(function () {
      /** @type {jQuery} HTMLParagraphElement */const $p = $(this);
      /** @type {jQuery} HTMLElement[] */
      const $c = $p.children();
      !$c.length ? $p.remove() : $c.unwrap();
    });
  };
});